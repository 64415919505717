import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/codebuild/output/src4126771764/src/apps/dfe/src/client/webVitals.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4126771764/src/node_modules/.pnpm/next@14.2.10_@babel+core@7.25.2_@opentelemetry+api@1.7.0_@playwright+test@1.46.1_react-dom@18_gqcgnclv35xqe3uswtftypt4dy/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Source_Sans_3\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"600\",\"700\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-source-sans-3\"}],\"variableName\":\"SS3\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4126771764/src/node_modules/.pnpm/next@14.2.10_@babel+core@7.25.2_@opentelemetry+api@1.7.0_@playwright+test@1.46.1_react-dom@18_gqcgnclv35xqe3uswtftypt4dy/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../public/fonts/URWDIN-XLight.otf\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"../public/fonts/URWDIN-Thin.otf\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"../public/fonts/URWDIN-Light.otf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../public/fonts/URWDIN-LightItalic.otf\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"../public/fonts/URWDIN-Regular.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../public/fonts/URWDIN-Italic.otf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../public/fonts/URWDIN-Medium.otf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../public/fonts/URWDIN-MediumItalic.otf\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../public/fonts/URWDIN-Demi.otf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../public/fonts/URWDIN-DemiItalic.otf\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"../public/fonts/URWDIN-Bold.otf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../public/fonts/URWDIN-BoldItalic.otf\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../public/fonts/URWDIN-Black.otf\",\"weight\":\"800\",\"style\":\"normal\"},{\"path\":\"../public/fonts/URWDIN-BlackItalic.otf\",\"weight\":\"800\",\"style\":\"italic\"}],\"display\":\"swap\",\"variable\":\"--font-din\"}],\"variableName\":\"DIN\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4126771764/src/node_modules/.pnpm/next@14.2.10_@babel+core@7.25.2_@opentelemetry+api@1.7.0_@playwright+test@1.46.1_react-dom@18_gqcgnclv35xqe3uswtftypt4dy/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../public/fonts/URWDINSemiCond-Bold.otf\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-din-semi-cond\"}],\"variableName\":\"DinSemiCond\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/codebuild/output/src4126771764/src/packages/ui-dfe/components/src/lib/ThemeProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider","default","useAuth"] */ "/codebuild/output/src4126771764/src/packages/ui-dfe/hooks/src/lib/useAuth/AuthProvider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4126771764/src/packages/ui-typesense-client/src/lib/TypesenseProvider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4126771764/src/packages/ui-typesense-client/src/lib/useTypesenseSearch.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4126771764/src/packages/ui-typesense-client/src/lib/useUiTypesenseClient.ts");
